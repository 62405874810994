import { useScript } from 'usehooks-ts';

export function ClientScripts() {
  if (window.location.hostname === 'app.staging.granular')
    useScript('https://static.zdassets.com/ekr/snippet.js?key=6043c43e-f1b9-4277-8d17-c9ae728bad63', {
      id: 'zendesk-snippet',
    });

  return null;
}
