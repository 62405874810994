import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as JotaiProvider } from 'jotai';
import { WithIntlProvider } from '~/intl/index';
import { ToastContextProvider } from './toast';
import { UrqlProvider } from './urql';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type ProvidersType = [React.ElementType, Record<string, unknown>];
type ChildrenType = {
  children: Array<React.ElementType>;
};

export function buildProvidersTree(componentsWithProps: Array<ProvidersType>) {
  const initialComponent = ({ children }: ChildrenType) => <>{children}</>;
  return componentsWithProps.reduce(
    (AccumulatedComponents: React.ElementType, [Provider, props = {}]: ProvidersType) => {
      return ({ children }: ChildrenType) => {
        return (
          <AccumulatedComponents>
            <Provider {...props}>{children}</Provider>
          </AccumulatedComponents>
        );
      };
    },
    initialComponent,
  );
}

export const ProvidersTree = buildProvidersTree([
  [
    Auth0Provider,
    {
      domain: AUTH0_DOMAIN,
      clientId: AUTH0_CLIENT_ID,
      audience: AUTH0_AUDIENCE,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        audience: AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
      },
    },
  ],
  [JotaiProvider, {}],
  [WithIntlProvider, {}],
  [QueryClientProvider, { client: queryClient }],
  [UrqlProvider, {}],
  [ToastContextProvider, {}],
]);
