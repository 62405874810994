import { useAuth0 } from '@auth0/auth0-react';
import { authExchange } from '@urql/exchange-auth';
import { jwtDecode } from 'jwt-decode';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { type Client, Provider, cacheExchange, createClient, fetchExchange } from 'urql';

export function UrqlProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [client, setClient] = useState<Client>();

  useEffect(() => {
    const auth0Exchange = authExchange(async (utils) => {
      let token = await getAccessTokenSilently();

      return {
        addAuthToOperation(operation) {
          if (!token) return operation;
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
        async refreshAuth() {
          token = await getAccessTokenSilently();
          if (!token) {
            logout();
          }
        },
        willAuthError() {
          if (!token) return true;

          const decodedToken = jwtDecode<{ [key: string]: number }>(token);

          if ('exp' in decodedToken) {
            const now = Date.now() / 1000;
            return now >= decodedToken.exp;
          }

          return false;
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
        },
      };
    });

    const newClient = createClient({
      url: `${BACKEND_HOST}${API_PATH}/graphql`,
      exchanges: [cacheExchange, auth0Exchange, fetchExchange],
    });

    setClient(newClient);
  }, [getAccessTokenSilently, logout]);

  if (!client) {
    return children;
  }

  return <Provider value={client}>{children}</Provider>;
}
